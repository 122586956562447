import React from 'react';
import './App.css';

// from https://solomid-resources.s3.amazonaws.com/blitz/tft/data/items.json
const items = {
  "bfsword"               : {
    "key"        : "bfsword",
    "name"       : "B.F. Sword",
    "type"       : "Offensive",
    "bonus"      : "+20 Attack Damage.",
    "tier"       : 1,
    "depth"      : 1,
    "stats"      : [
      {
        "name"   : "attackDamage",
        "title"  : "Attack Damage",
        "amount" : "+20"
      }
    ],
    "kind"       : "basic",
    "buildsInto" : [
      "infinityedge",
      "hextechgunblade",
      "swordofthedivine",
      "spearofshojin",
      "guardianangel",
      "bloodthirster",
      "zekesherald",
      "youmuusghostblade"
    ],
    "champs"     : ["Ashe", "Yasuo", "Zed"]
  },
  "needlesslylargerod"    : {
    "key"        : "needlesslylargerod",
    "name"       : "Needlessly Large Rod",
    "type"       : "Offensive",
    "bonus"      : "+20% Spell Damage",
    "tier"       : 1,
    "depth"      : 1,
    "stats"      : [
      {
        "name"   : "abilityPower",
        "title"  : "Spell Damage",
        "amount" : "+20%"
      }
    ],
    "kind"       : "basic",
    "buildsInto" : [
      "hextechgunblade",
      "rabadonsdeathcap",
      "guinsoosrageblade",
      "ludensecho",
      "locketoftheironsolari",
      "ionicspark",
      "morellonomicon",
      "yuumi"
    ],
    "champs"     : ["Ashe", "Yasuo", "Zed"]
  },
  "recurvebow"            : {
    "key"        : "recurvebow",
    "name"       : "Recurve Bow",
    "type"       : "Offensive",
    "bonus"      : "+20% Attack Speed",
    "tier"       : 1,
    "depth"      : 1,
    "stats"      : [
      {
        "name"   : "attackSpeed",
        "title"  : "Attack Speed",
        "amount" : "+20%"
      }
    ],
    "kind"       : "basic",
    "buildsInto" : [
      "swordofthedivine",
      "guinsoosrageblade",
      "rapidfirecannon",
      "statikkshiv",
      "phantomdancer",
      "cursedblade",
      "titanichydra",
      "bladeoftheruinedking"
    ],
    "champs"     : ["Ashe", "Yasuo", "Zed"]
  },
  "tearofthegoddess"      : {
    "key"        : "tearofthegoddess",
    "name"       : "Tear of the Goddess",
    "type"       : "Utility",
    "bonus"      : "+20 Starting Mana.",
    "tier"       : 1,
    "depth"      : 1,
    "stats"      : [
      {
        "name"   : "mana",
        "title"  : "Starting Mana",
        "amount" : "+20"
      }
    ],
    "kind"       : "basic",
    "buildsInto" : [
      "spearofshojin",
      "ludensecho",
      "statikkshiv",
      "seraphsembrace",
      "frozenheart",
      "hush",
      "redemption",
      "darkin"
    ],
    "champs"     : ["Ashe", "Yasuo", "Zed"]
  },
  "chainvest"             : {
    "key"        : "chainvest",
    "name"       : "Chain Vest",
    "type"       : "Defensive",
    "bonus"      : "+20 Armor.",
    "tier"       : 1,
    "depth"      : 1,
    "stats"      : [
      {
        "name"   : "armor",
        "title"  : "Armor",
        "amount" : "+20"
      }
    ],
    "kind"       : "basic",
    "buildsInto" : [
      "guardianangel",
      "locketoftheironsolari",
      "phantomdancer",
      "frozenheart",
      "thornmail",
      "swordbreaker",
      "redbuff",
      "knightsvow"
    ],
    "champs"     : ["Ashe", "Yasuo", "Zed"]
  },
  "negatroncloak"         : {
    "key"        : "negatroncloak",
    "name"       : "Negatron Cloak",
    "type"       : "Defensive",
    "bonus"      : "+20 Magic Resist.",
    "tier"       : 1,
    "depth"      : 1,
    "stats"      : [
      {
        "name"   : "magicResist",
        "title"  : "Magic Resist",
        "amount" : "+20"
      }
    ],
    "kind"       : "basic",
    "buildsInto" : [
      "bloodthirster",
      "ionicspark",
      "cursedblade",
      "hush",
      "swordbreaker",
      "dragonsclaw",
      "zephyr",
      "runaanshurricane"
    ],
    "champs"     : ["Ashe", "Yasuo", "Zed"]
  },
  "giantsbelt"            : {
    "key"        : "giantsbelt",
    "name"       : "Giant's Belt",
    "type"       : "Defensive",
    "bonus"      : "+200 Health.",
    "tier"       : 1,
    "depth"      : 1,
    "stats"      : [
      {
        "name"   : "health",
        "title"  : "Health",
        "amount" : "+200"
      }
    ],
    "kind"       : "basic",
    "buildsInto" : [
      "zekesherald",
      "morellonomicon",
      "titanichydra",
      "redemption",
      "redbuff",
      "zephyr",
      "warmogsarmor",
      "frozenmallet"
    ],
    "champs"     : ["Ashe", "Yasuo", "Zed"]
  },
  "spatula"               : {
    "key"        : "spatula",
    "name"       : "Spatula",
    "type"       : "Utility",
    "bonus"      : "It must do something...",
    "tier"       : 1,
    "depth"      : 1,
    "stats"      : [
      {
        "name"   : null,
        "title"  : "It must do something...",
        "amount" : null
      }
    ],
    "kind"       : "basic",
    "buildsInto" : [
      "youmuusghostblade",
      "yuumi",
      "bladeoftheruinedking",
      "darkin",
      "knightsvow",
      "runaanshurricane",
      "frozenmallet",
      "forceofnature"
    ],
    "champs"     : ["Ashe", "Yasuo", "Zed"]
  },
  "forceofnature"         : {
    "key"        : "forceofnature",
    "name"       : "Force of Nature",
    "type"       : "Utility",
    "bonus"      : "Gain + team size.",
    "tier"       : 1,
    "depth"      : 2,
    "buildsFrom" : ["spatula", "spatula"],
    "kind"       : "advanced",
    "champs"     : ["Ashe", "Yasuo", "Zed"]
  },
  "bladeoftheruinedking"  : {
    "key"        : "bladeoftheruinedking",
    "name"       : "Blade of the Ruined King",
    "type"       : "Offensive",
    "bonus"      : "Wearer is also a Blademaster.",
    "tier"       : 2,
    "depth"      : 2,
    "buildsFrom" : ["spatula", "recurvebow"],
    "kind"       : "advanced",
    "champs"     : ["Ashe", "Yasuo", "Zed"]
  },
  "bloodthirster"         : {
    "key"        : "bloodthirster",
    "name"       : "Bloodthirster",
    "type"       : "Defensive",
    "bonus"      : "Attacks heal for 50% of damage.",
    "tier"       : 3,
    "depth"      : 2,
    "buildsFrom" : ["bfsword", "negatroncloak"],
    "kind"       : "advanced",
    "champs"     : ["Ashe", "Yasuo", "Zed"]
  },
  "cursedblade"           : {
    "key"        : "cursedblade",
    "name"       : "Cursed Blade",
    "type"       : "Utility",
    "bonus"      : "Attacks have a 20% chance to Shrink (-1 enemy star, down to level 0).",
    "tier"       : 3,
    "depth"      : 2,
    "buildsFrom" : ["negatroncloak", "recurvebow"],
    "kind"       : "advanced",
    "champs"     : ["Ashe", "Yasuo", "Zed"]
  },
  "darkin"                : {
    "key"        : "darkin",
    "name"       : "Darkin",
    "type"       : "Utility",
    "bonus"      : "Wearer is also a Demon.",
    "tier"       : 2,
    "depth"      : 2,
    "buildsFrom" : ["spatula", "tearofthegoddess"],
    "kind"       : "advanced",
    "champs"     : ["Ashe", "Yasuo", "Zed"]
  },
  "dragonsclaw"           : {
    "key"        : "dragonsclaw",
    "name"       : "Dragon's Claw",
    "type"       : "Defensive",
    "bonus"      : "83% resistance to magic damage.",
    "tier"       : 3,
    "depth"      : 2,
    "buildsFrom" : ["negatroncloak", "negatroncloak"],
    "kind"       : "advanced",
    "champs"     : ["Ashe", "Yasuo", "Zed"]
  },
  "frozenheart"           : {
    "key"        : "frozenheart",
    "name"       : "Frozen Heart",
    "type"       : "Defensive",
    "bonus"      : "Adjacent enemies lose 25% Attack Speed.",
    "tier"       : 4,
    "depth"      : 2,
    "buildsFrom" : ["tearofthegoddess", "chainvest"],
    "kind"       : "advanced",
    "champs"     : ["Ashe", "Yasuo", "Zed"]
  },
  "frozenmallet"          : {
    "key"        : "frozenmallet",
    "name"       : "Frozen Mallet",
    "type"       : "Utility",
    "bonus"      : "Wearer is also a Glacial.",
    "tier"       : 3,
    "depth"      : 2,
    "buildsFrom" : ["giantsbelt", "spatula"],
    "kind"       : "advanced",
    "champs"     : ["Ashe", "Yasuo", "Zed"]
  },
  "guardianangel"         : {
    "key"        : "guardianangel",
    "name"       : "Guardian Angel",
    "type"       : "Utility",
    "bonus"      : "Revives champ with 800 HP after 2 seconds.",
    "tier"       : 1,
    "depth"      : 2,
    "buildsFrom" : ["bfsword", "chainvest"],
    "kind"       : "advanced",
    "champs"     : ["Ashe", "Yasuo", "Zed"]
  },
  "guinsoosrageblade"     : {
    "key"        : "guinsoosrageblade",
    "name"       : "Guinsoo's Rageblade",
    "type"       : "Offensive",
    "bonus"      : "Attacks give 5% Attack Speed. Stacks infinitely.",
    "tier"       : 3,
    "depth"      : 2,
    "buildsFrom" : ["needlesslylargerod", "recurvebow"],
    "kind"       : "advanced",
    "champs"     : ["Ashe", "Yasuo", "Zed"]
  },
  "hextechgunblade"       : {
    "key"        : "hextechgunblade",
    "name"       : "Hextech Gunblade",
    "type"       : "Defensive",
    "bonus"      : "Heal for 33% of all damage dealt.",
    "tier"       : 3,
    "depth"      : 2,
    "buildsFrom" : ["bfsword", "needlesslylargerod"],
    "kind"       : "advanced",
    "champs"     : ["Ashe", "Yasuo", "Zed"]
  },
  "hush"                  : {
    "key"        : "hush",
    "name"       : "Hush",
    "type"       : "Utility",
    "bonus"      : "Attacks have a 50% chance to Silence for 3 seconds.",
    "tier"       : 3,
    "depth"      : 2,
    "buildsFrom" : ["tearofthegoddess", "negatroncloak"],
    "kind"       : "advanced",
    "champs"     : ["Ashe", "Yasuo", "Zed"]
  },
  "infinityedge"          : {
    "key"        : "infinityedge",
    "name"       : "Infinity Edge",
    "type"       : "Offensive",
    "bonus"      : "Critical Strikes deal +150% damage.",
    "tier"       : 4,
    "depth"      : 2,
    "buildsFrom" : ["bfsword", "bfsword"],
    "kind"       : "advanced",
    "champs"     : ["Ashe", "Yasuo", "Zed"]
  },
  "ionicspark"            : {
    "key"        : "ionicspark",
    "name"       : "Ionic Spark",
    "type"       : "Utility",
    "bonus"      : "Whenever an enemy casts a spell they take 150 true damage.",
    "tier"       : 3,
    "depth"      : 2,
    "buildsFrom" : ["negatroncloak", "needlesslylargerod"],
    "kind"       : "advanced",
    "champs"     : ["Ashe", "Yasuo", "Zed"]
  },
  "knightsvow"            : {
    "key"        : "knightsvow",
    "name"       : "Knights Vow",
    "type"       : "Utility",
    "bonus"      : "Wearer is also a Knight.",
    "tier"       : 3,
    "depth"      : 2,
    "buildsFrom" : ["chainvest", "spatula"],
    "kind"       : "advanced",
    "champs"     : ["Ashe", "Yasuo", "Zed"]
  },
  "locketoftheironsolari" : {
    "key"        : "locketoftheironsolari",
    "name"       : "Locket of the Iron Solari",
    "type"       : "Defensive",
    "bonus"      : "On start of combat, allies 2 spaces to the left and right gain <strong style='color: white;'>250 shield for 4s.</span>",
    "tier"       : 5,
    "depth"      : 2,
    "buildsFrom" : ["needlesslylargerod", "chainvest"],
    "kind"       : "advanced",
    "champs"     : ["Ashe", "Yasuo", "Zed"]
  },
  "ludensecho"            : {
    "key"        : "ludensecho",
    "name"       : "Luden's Echo",
    "type"       : "Offensive",
    "bonus"      : "Deal 200 splash damage on ability hit.",
    "tier"       : 4,
    "depth"      : 2,
    "buildsFrom" : ["needlesslylargerod", "tearofthegoddess"],
    "kind"       : "advanced",
    "champs"     : ["Ashe", "Yasuo", "Zed"]
  },
  "morellonomicon"        : {
    "key"        : "morellonomicon",
    "name"       : "Morellonomicon",
    "type"       : "Offensive",
    "bonus"      : "Spells burn for 4% of max HP per second and disable healing.",
    "tier"       : 2,
    "depth"      : 2,
    "buildsFrom" : ["needlesslylargerod", "giantsbelt"],
    "kind"       : "advanced",
    "champs"     : ["Ashe", "Yasuo", "Zed"]
  },
  "phantomdancer"         : {
    "key"        : "phantomdancer",
    "name"       : "Phantom Dancer",
    "type"       : "Defensive",
    "bonus"      : "Wearer dodges all Critical Strikes (including ability crits).",
    "tier"       : 2,
    "depth"      : 2,
    "buildsFrom" : ["chainvest", "recurvebow"],
    "kind"       : "advanced",
    "champs"     : ["Ashe", "Yasuo", "Zed"]
  },
  "rabadonsdeathcap"      : {
    "key"        : "rabadonsdeathcap",
    "name"       : "Rabadon's Deathcap",
    "type"       : "Offensive",
    "bonus"      : "Wearer's Ability Damage increased by 50%.",
    "tier"       : 4,
    "depth"      : 2,
    "buildsFrom" : ["needlesslylargerod", "needlesslylargerod"],
    "kind"       : "advanced",
    "champs"     : ["Ashe", "Yasuo", "Zed"]
  },
  "rapidfirecannon"       : {
    "key"        : "rapidfirecannon",
    "name"       : "Rapid Firecannon",
    "type"       : "Offensive",
    "bonus"      : "Attacks cannot be dodged. Attack Range is doubled.",
    "tier"       : 1,
    "depth"      : 2,
    "buildsFrom" : ["recurvebow", "recurvebow"],
    "kind"       : "advanced",
    "champs"     : ["Ashe", "Yasuo", "Zed"]
  },
  "redbuff"               : {
    "key"        : "redbuff",
    "name"       : "Red Buff",
    "type"       : "Offensive",
    "bonus"      : "Attacks burn for 13% of max HP over 5s and disable healing.",
    "tier"       : 2,
    "depth"      : 2,
    "buildsFrom" : ["chainvest", "giantsbelt"],
    "kind"       : "advanced",
    "champs"     : ["Ashe", "Yasuo", "Zed"]
  },
  "redemption"            : {
    "key"        : "redemption",
    "name"       : "Redemption",
    "type"       : "Utility",
    "bonus"      : "At 25% health heal all nearby allies for 1000 HP.",
    "tier"       : 5,
    "depth"      : 2,
    "buildsFrom" : ["tearofthegoddess", "giantsbelt"],
    "kind"       : "advanced",
    "champs"     : ["Ashe", "Yasuo", "Zed"]
  },
  "runaanshurricane"      : {
    "key"        : "runaanshurricane",
    "name"       : "Runaan's Hurricane",
    "type"       : "Offensive",
    "bonus"      : "Attacks hit 1 additional enemy. This extra hit does 75% damage and applies on-hit effects.",
    "tier"       : 4,
    "depth"      : 2,
    "buildsFrom" : ["spatula", "negatroncloak"],
    "kind"       : "advanced",
    "champs"     : ["Ashe", "Yasuo", "Zed"]
  },
  "seraphsembrace"        : {
    "key"        : "seraphsembrace",
    "name"       : "Seraph's Embrace",
    "type"       : "Utility",
    "bonus"      : "Regain 20 mana each time a spell is cast",
    "tier"       : 2,
    "depth"      : 2,
    "buildsFrom" : ["tearofthegoddess", "tearofthegoddess"],
    "kind"       : "advanced",
    "champs"     : ["Ashe", "Yasuo", "Zed"]
  },
  "spearofshojin"         : {
    "key"        : "spearofshojin",
    "name"       : "Spear of Shojin",
    "type"       : "Utility",
    "bonus"      : "After casting, gain 15% of max mana per attack.",
    "tier"       : 4,
    "depth"      : 2,
    "buildsFrom" : ["bfsword", "tearofthegoddess"],
    "kind"       : "advanced",
    "champs"     : ["Ashe", "Yasuo", "Zed"]
  },
  "statikkshiv"           : {
    "key"        : "statikkshiv",
    "name"       : "Statikk Shiv",
    "type"       : "Offensive",
    "bonus"      : "Every 3rd attack deals 90 splash magical damage to 3 enemies.",
    "tier"       : 2,
    "depth"      : 2,
    "buildsFrom" : ["recurvebow", "tearofthegoddess"],
    "kind"       : "advanced",
    "champs"     : ["Ashe", "Yasuo", "Zed"]
  },
  "swordbreaker"          : {
    "key"        : "swordbreaker",
    "name"       : "Sword Breaker",
    "type"       : "Utility",
    "bonus"      : "Attacks have a 25% to disarm.",
    "tier"       : 3,
    "depth"      : 2,
    "buildsFrom" : ["chainvest", "negatroncloak"],
    "kind"       : "advanced",
    "champs"     : ["Ashe", "Yasuo", "Zed"]
  },
  "swordofthedivine"      : {
    "key"        : "swordofthedivine",
    "name"       : "Sword of the Divine",
    "type"       : "Offensive",
    "bonus"      : "Every 1s gain a 7% chance to gain 100% Critical Strike.",
    "tier"       : 5,
    "depth"      : 2,
    "buildsFrom" : ["recurvebow", "bfsword"],
    "kind"       : "advanced",
    "champs"     : ["Ashe", "Yasuo", "Zed"]
  },
  "thornmail"             : {
    "key"        : "thornmail",
    "name"       : "Thornmail",
    "type"       : "Defensive",
    "bonus"      : "Reflect 100% of of physical damage mitigated as Magic Damage.",
    "tier"       : 3,
    "depth"      : 2,
    "buildsFrom" : ["chainvest", "chainvest"],
    "kind"       : "advanced",
    "champs"     : ["Ashe", "Yasuo", "Zed"]
  },
  "titanichydra"          : {
    "key"        : "titanichydra",
    "name"       : "Titanic Hydra",
    "type"       : "Offensive",
    "bonus"      : "Attacks deal 10% of the wearer's max HP as splash.",
    "tier"       : 4,
    "depth"      : 2,
    "buildsFrom" : ["giantsbelt", "recurvebow"],
    "kind"       : "advanced",
    "champs"     : ["Ashe", "Yasuo", "Zed"]
  },
  "warmogsarmor"          : {
    "key"        : "warmogsarmor",
    "name"       : "Warmog's Armor",
    "type"       : "Defensive",
    "bonus"      : "Regenerate 6% max <strong style='color: #20995D;'>Health</strong> per second.",
    "tier"       : 2,
    "depth"      : 2,
    "buildsFrom" : ["giantsbelt", "giantsbelt"],
    "kind"       : "advanced",
    "champs"     : ["Ashe", "Yasuo", "Zed"]
  },
  "youmuusghostblade"     : {
    "key"        : "youmuusghostblade",
    "name"       : "Youmuu's Ghostblade",
    "type"       : "Utility",
    "bonus"      : "Wearer is also an Assassin.",
    "tier"       : 2,
    "depth"      : 2,
    "buildsFrom" : ["spatula", "bfsword"],
    "kind"       : "advanced",
    "champs"     : ["Ashe", "Yasuo", "Zed"]
  },
  "yuumi"                 : {
    "key"        : "yuumi",
    "name"       : "Yuumi",
    "type"       : "Utility",
    "bonus"      : "Wearer is also a Sorcerer.",
    "tier"       : 2,
    "depth"      : 2,
    "buildsFrom" : ["spatula", "needlesslylargerod"],
    "kind"       : "advanced",
    "champs"     : ["Ashe", "Yasuo", "Zed"]
  },
  "zekesherald"           : {
    "key"        : "zekesherald",
    "name"       : "Zeke's Herald",
    "type"       : "Utility",
    "bonus"      : "On start of combat, allies 2 spaces to the left and right gain +15% Attack Speed.",
    "tier"       : 4,
    "depth"      : 2,
    "buildsFrom" : ["bfsword", "giantsbelt"],
    "kind"       : "advanced",
    "champs"     : ["Ashe", "Yasuo", "Zed"]
  },
  "zephyr"                : {
    "key"        : "zephyr",
    "name"       : "Zephyr",
    "type"       : "Utility",
    "bonus"      : "On start of combat banish an enemy.",
    "tier"       : 5,
    "depth"      : 2,
    "buildsFrom" : ["negatroncloak", "giantsbelt"],
    "kind"       : "advanced",
    "champs"     : ["Ashe", "Yasuo", "Zed"]
  }
}


function getComboItems() {
  return Object.values(items).filter(i => typeof i.buildsFrom != "undefined")
}

function getItemCombo(item) {
  return getComboItems()
    .filter(i => i.buildsFrom.indexOf(item.key) !== -1)
    .map(i => ({
      with  : items[i.buildsFrom[1 - i.buildsFrom.indexOf(item.key)]],// get the other element of an array of size 2
      gives : items[i.key]
    }))
    .filter(c => c.with.key !== "spatula");
}

function preferList(item, combos) {
  return <div className="base m-xs text">
    <div className="p-m pb-0 ta-c"><img src={require(`./images/${item.name}.png`)} alt={item.name}/></div>
    <div className="ta-c" style={{ height : '2rem' }}>{item.name}</div>
    <ul className="combo">
      {getItemCombo(item).map(
        combo => (
          <li className="p-s m-xs" key={combo.gives.key}>
            <img src={require(`./images/${combo.with.name}.png`)} alt={combo.with.name} title={combo.with.bonus}
                 width={25}/>
            <div className="gives"><span className="prefixed arr" style={{ content : '»' }}>&nbsp;<img
              src={require(`./images/${combo.gives.name}.png`)} alt={combo.gives.name}
              title={combo.gives.name + ': ' + combo.gives.bonus}
              width={45}/></span>
            </div>
            <div className="smallDesc">{combo.gives.bonus}</div>
          </li>
        )
      )}</ul>
  </div>
}

function App() {
  return (
    <div className="layout">
      {Object.values(items)
        .filter(item => item.buildsInto != null)
        .map(preferList)}
    </div>
  );
}

export default App;
